import React from 'react'
import { graphql } from 'gatsby';
import styled, { ThemeProvider } from 'styled-components';

import SEO from "utils/Seo";
import theme from "utils/Theme";
import GlobalStyles from "utils/GlobalStyles";
import NYHeader from 'components/new-york/header';
import Footer from 'components/new-york/footer';
import StyledButton from "components/shared/StyledButton";
import DeloreanAnimation from "components/Animations/DeloreanAnimation";
import { media } from 'utils/Media';

import 'scss/main.scss'

const ThankYou = styled.div`
    width: 100%;
    height: calc(100vh - 80px);
    background: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    section {
        width: 90%;
        margin: 0 auto;
        .button {
            text-align: center;
            margin: 0 auto;
        }
        h1 , p { 
        text-align: center;
    }
    }
    @media ${media.md} {
        height: calc(100vh - 105px);
    }
`


const ThankYouPage = ({ data }) => {
    const { logo, videoImage } = data
  return (
    <ThemeProvider theme={theme}>
      <SEO title="Landing Page" />
      <GlobalStyles />
      <NYHeader logo={logo} noButton />
      <ThankYou>
          <section>
              <DeloreanAnimation />
            <h1>Thank you for signing up</h1>
            <div className="button">
                <StyledButton to="/new-york/">
                    HOME
                </StyledButton>
            </div>
          </section>
      </ThankYou>
      <Footer />
    </ThemeProvider>
  )
}

export default ThankYouPage


export const ThankYouQuery = graphql`
  query ThankYouQuery {
    logo: file(relativePath: { eq: "BTTF-LOGO-2020-NEW.png" }) {
      childImageSharp {
        gatsbyImageData(width: 500)
      }
    }
    videoImage: file(relativePath: { eq: "HomePage/new-trailer.png" }) {
                    childImageSharp {
                       gatsbyImageData(width: 1200)
                    }
                }
   
  }
`;
