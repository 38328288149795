import React, {useState} from 'react'
import StyledButton from "components/shared/StyledButton";
import styled from 'styled-components';
import GetImage from '../../utils/getImage';
import Social from 'components/shared/Social'
import {media} from 'utils/Media'
import {Link as SmoothLink, animateScroll as scroll} from "react-scroll";
import {Link} from 'gatsby';
import Region from 'utils/Region';


const NYHeaderStyles = styled.header`
  width: 100%;
  border-bottom: solid 2px #fff;
  background: #000;

  section {
    max-width: 1200px;
    width: 90%;
    padding: .75rem 0;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .logo {
      width: 100px;
    }

    .button {
      position: relative;
    }

    @media ${media.md} {
      justify-content: space-between;
      width: 90%;
      .logo {
        flex: 0 1 auto;
        width: 150px;

      }

      .button {
        margin-right: 2rem;
        flex: 0 1 25%;
        max-width: 185px;
      }
    }
    @media ${media.lg} {

      .logo {
        margin-left: -3rem;

      }

    }
  }
`
const Button = styled(StyledButton)`
  width: 100%;
`
const HeaderSocials = styled(Social)`
  display: none;
  visibility: hidden;
  @media ${media.lg} {
    display: block;
    flex: 0 1 30%;
  }
  @media ${media.xl} {
    display: block;
    flex: 0 1 22%;
  }
`

const NYHeader = ({logo, noButton}) => {

    return (
        <NYHeaderStyles>
            <section>
                <HeaderSocials

                    facebook="https://www.facebook.com/BTTFBway/"
                    twitter="https://twitter.com/BttfBway"
                    instagram="https://www.instagram.com/bttfbway/"
                    tiktok="https://www.tiktok.com/@bttfbway"
                    youtube="https://www.youtube.com/channel/UCeSzzeNFw5t-A7sg-Voaxyw"
                />
                <div className="logo">
                    <Link to="/new-york/">
                        <GetImage data={logo}/>
                    </Link>
                </div>
                <div className="button">
                    {noButton ? '' : (
                        <>
                            <SmoothLink to="signup"
                                        spy={true}
                                        smooth={true}
                                        offset={-70}
                                        duration={500}>
                                <Button button>
                                    SIGN UP
                                </Button>
                            </SmoothLink>
                            <Region $header region={`london`} />
                        </>
                    )}
                </div>
            </section>
        </NYHeaderStyles>
    )
}

export default NYHeader