import React from 'react'
import styled from 'styled-components'
import americanExpress from "images/new-york/americanExpress.svg"
import {media} from 'utils/Media';
import NYFooterLinks from './nyFooterLinks';

const FooterStyles = styled.footer`
  position: relative;
  z-index: 10;
  width: 100%;
  padding: 2rem 0;
  text-align: center;
  background: #000;

  section {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column-reverse;
    
    @media ${media.md} {
      font-size: .9rem;
      flex-direction: row;
    }
    @media ${media.lg} {
      flex-wrap: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-start;
    }
  }

  .links {
    margin-top: 1.5rem;
    position: relative;
    text-align: center;
    color: #fff;
    line-height: 1.5;
    z-index: 10;
    width: 100%;
    @media ${media.lg} {
      width: 70%;
    }

    .desktop {
      display: none;
    }

    a {
      font-family: ${props => props.theme.font.family.base} !important;
      color: #fff !important;
    }
  }

  @media ${media.md} {
    width: 90%;
    margin: 0 auto;
    .links {
      font-size: .9rem;

      a {
        color: #fff !important;
        text-decoration: none;
        transition: color .25s ease-in-out;
        cursor: pointer;

        &:hover {
          color: ${props => props.theme.colors.red} !important;
        }
      }

      .desktop {
        display: inline;
      }

      .mob {
        display: none;
      }
    }
  }
`
const Amex = styled.div`
  display: flex;
  color: white;
  align-items: center;
  text-align: right;
  width: initial;
  margin: 0 auto;
  
  @media ${media.lg} {
    width: 30%;
  }

  .left {
    flex-grow: 1;
  }

  .right {
    width: 80px;
  }

  .americanExpress {
    width: 100%;
  }
`

const Footer = () => {
    return (
        <FooterStyles>
            <section>
                {/* <Social
                facebook="https://www.facebook.com/BTTFBway/"
                twitter="https://twitter.com/BttfBway"
                instagram="https://www.instagram.com/bttfbway/"
                tiktok="https://www.tiktok.com/@bttfbway"
                youtube="https://www.youtube.com/channel/UCeSzzeNFw5t-A7sg-Voaxyw"
            /> */}
                <NYFooterLinks className="order-2 order-lg-1"/>
                <Amex className="order-1 order-lg-2">
                    <div className="left">
                        Official Partner of<br/>
                        <strong>Back to the Future The Musical</strong>
                    </div>
                    <div className="right">
                        <a href="https://www.telecharge.com/Broadway/Back-to-the-Future-the-Musical/Overview?AID=BWY001390400&utm_source=show_site&utm_campaign=Back-to-the-Future-the-MusicalSS&utm_medium=web"
                           target="_blank" rel="noopener">
                            <img className="americanExpress" src={americanExpress} alt="American Express"/>
                        </a>
                    </div>
                </Amex>
            </section>
        </FooterStyles>
    )
}

export default Footer